
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../form"
import ControlExpandCollapse from "../../controls/expandcollapse";

const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="apijwtuserjsonmenu"

// TODO Additional security/checks for user and usertype updating

const usertypepickerfields = [
	{"label":"Name", "dbfield": "usertype_name", "type": "text", "filtertype": "textbox"},
	{"label":"Description", "dbfield": "usertype_description", "type": "text", "filtertype": "textbox"},
];

const usertypepickerfilter = [
	//{"field":"onsorganization_id", "operation": ">", "value": 0},
];


const apijwtmenusetpickerfields = [
	{"label":"Name", "dbfield": "apijwtuserjsonmenutype_name", "type": "text", "filtertype": "textbox"},
	{"label":"Description", "dbfield": "apijwtuserjsonmenutype_description", "type": "text", "filtertype": "textbox"},
];

const apijwtmenusetpickerfilter = [
	//{"field":"onsorganization_id", "operation": ">", "value": 0},
];


const searchFields = [
	{"label":"Menu Set", "dbfield": "apijwtuserjsonmenutype_name", "type": "text", "filtertype": "text"},
	{"label":"User Type", "dbfield": "usertype_name", "type": "text", "filtertype": "text"},
	{"label":"Last Updated", "dbfield": "apijwtuserjsonmenu_updated", "type": "datetime", "filtertype": "datetime"}
];


const newFormFields = [
	[
		{"label":"Menu Set", "field": "apijwtuserjsonmenutype_name", "value": "", "input": "picker", "mode": "required", "pickerfields": apijwtmenusetpickerfields, "pickerfilters": apijwtmenusetpickerfilter},
		{"label":"User Type", "field": "usertype_name", "value": "", "input": "picker", "mode": "required", "pickerfields": usertypepickerfields, "pickerfilters": usertypepickerfilter},
		{"label":"Last Updated", "field": "apijwtuserjsonmenu_updated", "value": "", "input": "updatetime", "mode": "readonly"},
	]
];

const formFields = [
	[
		{"label":"Menu Set", "field": "apijwtuserjsonmenutype_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"User Type", "field": "usertype_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Last Updated", "field": "apijwtuserjsonmenu_updated", "value": "", "input": "updatetime", "mode": "readonly"},
	]
];

//swebappsiamnet/12/apijwtuserjsonmenu_data/1

const commonFields = [
	{"label":"Menu Set Code", "field": "apijwtuserjsonmenutype_prefix", "value": "", "input": "hidden", "mode": "normal"},
	//{"label":"JSON Data", "field": "apijwtuserjsonmenu_data", "value": "", "input": "textfile", "mode": "required", "info":"Sample: {\"title\": \"Main Menu\",\"url\":\"user/userlogin\",\"subitems\": [{\"title\": \"Sub Menu (optional)\",\"url\": \"user/userlogin\"}]}", "uploadtype": "webapp"},
	{"label":"JSON Data", "field": "apijwtuserjsonmenu_data", "value": "", "input": "textfile", "mode": "required", "filehost": "files.webapps.iamnet.com.ph", "filelocation":"menu/source/nn/userjsonmenutype/uploads", "uploadtype":"https"},
	//apijwtuserjsonmenu_sample
	{"label":"Sample Data", "field": "apijwtuserjsonmenu_sample", "value": "", "input": "text", "mode": "readonly"},
];


const COLLAPSEMODE_HIDE=1;

const ApijwtusertypemenuForm = ({location, allowadd, allowdelete}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [collapsestate, setCollapsestate] = useState({"sample":1,"definition":1});
	const [copieddone, setCopieddone] = useState(false);

	function copytriggered()
	{
		setCopieddone(true);
		setTimeout(function() {
			setCopieddone(false);
		}, 1500);
	}

	function setCollapsemode(attribute, newmode)
	{
		var tmpstate = JSON.parse(JSON.stringify(collapsestate));
		tmpstate[attribute] = newmode;
		//console.log(attribute, newmode);
		setCollapsestate(tmpstate);
	}

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		/*
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}
		*/

		//callback({"status":"Error", "message":"Debug"}); return;
		if (params.hasOwnProperty("apijwtuserjsonmenu_sample")) {
			delete params.apijwtuserjsonmenu_sample;
		}
		// TODO: Update final JSON file
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			callback(response);
		});

	}

	function customFieldFragment(inputlist, idx, isdisabled, fieldid, inputchangecallback)
	{
		if (inputlist[idx].field === "apijwtuserjsonmenu_sample") {
			const sampledata = {
				"main": [
					{
						"title": "No Sub Menu",
						"url": "user/userlogin"
					},
					{
						"title": "With Sub Menu",
						"url": "user/userlogin",
						"subitems": [
							{
								"title": "User",
								"url": "user/userlogin",
								"intentlist": ["keyword1","keyword2"]
							},
						]
					}
				],
				"popup": [
					{
						"title": "Optional Lists",
						"subitems": [
							{
								"title": "Link relative to root",
								"url": "listmanager/contactinfo"
							}
						]
					}
				],
				"reports": [
					{
						"title": "Report Group Name",
						"url": "customer/profile",
						"subitems": [
							{
								"title": "Relative to 'reports' folder",
								"url": "customer/profile"
							}
						]
					}
				]
			};
			return <div>
				<strong>
					Sample
					<>&nbsp;&nbsp;&nbsp;</>
					<ControlExpandCollapse
						defaultvalue={1}
						handleChange={(newmode)=>{setCollapsemode("sample",newmode);}}
					/>
				</strong>
				{collapsestate.sample !== COLLAPSEMODE_HIDE && <div
					style={{cursor:"pointer"}}
					onClick={() => {navigator.clipboard.writeText(JSON.stringify(sampledata, null, "\t")); copytriggered();}}>
					<div
						className="font-size-small"
						style={{
							maxHeight:"15vh",
							background:"#eeeeee",
							border: "1px dash gray",
							padding:"10px",
							overflowX:"scroll"
						}}
						dangerouslySetInnerHTML={{__html: JSON.stringify(sampledata, null, " &emsp; ").replace(/\n/g,"<br/>")}}

					/>
					<div className="font-size-small font-style-italic">{copieddone?"Copied!": "(click this area to copy)"}</div>
				</div>}
				<br/>

				<strong>
					Data Definition
					<>&nbsp;&nbsp;&nbsp;</>
					<ControlExpandCollapse
						defaultvalue={1}
						handleChange={(newmode)=>{setCollapsemode("definition",newmode);}}
					/>
				</strong>
				{collapsestate.definition !== COLLAPSEMODE_HIDE && <div>
					<strong>Top-level Attributes</strong>
					<div>
						<ul>
							<li>
								main - List of menu objects; Main menu, URLs are relative to root
							</li>
							<li>
								popup - [OPTIONAL] List of menu objects; List of allowed popup URLs, URLs are relative to root
							</li>
							<li>
								reports - [OPTIONAL] List of menu objects; Menu of reports, URLs are relative to "reports" path
							</li>
						</ul>
					</div>

					<strong>Menu Object Definition</strong>
					<div>
						<ul>
							<li>
								title - Text; Title
							</li>
							<li>
								url - Relative Path; No leading slash
							</li>
							<li>
								intentlist - [OPTIONAL] List of text; List of keywords to handle QR
							</li>
							<li>
								subitems - [OPTIONAL] List of menu objects
							</li>
						</ul>
					</div>
				</div>}

			</div>
		}
		return null;
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "apijwtuserjsonmenu_data") {
				try {
					JSON.parse(mainform[idx].value);
					// todo additional checking
				} catch(checkjsonerr) {
					return {"status":"Error", "message":"Error"+checkjsonerr.message.replace("JSON.parse","")};

				}
			}
			idx++;
		}

		return {"status":"OK"};
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={allowadd}
				allowdelete={allowdelete}
				pagetitle={pagetitle}
				mobilerowtitlefield={["apijwtuserjsonmenutype_name", "usertype_name"]}
				searchFields={searchFields}
				editFields={[formFields[0].concat(commonFields)]}
				newformFields={[newFormFields[0].concat(commonFields)]}
				customSubmit={customSubmit}
				customValidateValues={formValidateValues}
				customFieldFragment={customFieldFragment}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default ApijwtusertypemenuForm;
